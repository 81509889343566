import Cookies from 'js-cookie';

class Storage {
  constructor() {
    return this._checkIfLocalStorageWorks()
      ? {
          getItem: this._getItemLocalStorage,
          setItem: this._setItemLocalStorage,
          removeItem: this._removeItemLocalStorage,
          clear: this._clearLocalStorage
        }
      : {
          getItem: this._getItemCookie,
          setItem: this._setItemCookie,
          removeItem: this._removeItemCookie,
          clear: this._clearCookies
        };
  }

  // Checks if local storage is available and works
  _checkIfLocalStorageWorks() {
    if (typeof localStorage !== 'undefined') {
      try {
        localStorage.setItem('feature_test', 'yes');
        if (localStorage.getItem('feature_test') === 'yes') {
          localStorage.removeItem('feature_test');
          // localStorage is enabled
          return true;
        } else {
          // localStorage is disabled
          return false;
        }
      } catch (e) {
        // localStorage is disabled
        return false;
      }
    } else {
      // localStorage is not available
      return false;
    }
  }

  /*
   * Local Storage APIs
   */
  _getItemLocalStorage(key) {
    return window.localStorage.getItem(key);
  }

  _setItemLocalStorage(key, value) {
    return window.localStorage.setItem(key, value);
  }

  _removeItemLocalStorage(key) {
    return window.localStorage.removeItem(key);
  }

  _clearLocalStorage() {
    return window.localStorage.clear();
  }

  /*
   * Cookie APIs
   */
  _getItemCookie(key) {
    return Cookies.get(key);
  }

  _setItemCookie(key, value, attr = { expires: 30, path: '' }) {
    Cookies.set(key, value, attr);
    return; //return undefined just like localStorage
  }

  _removeItemCookie(key) {
    // to delete an k/v set the key to empty
    Cookies.remove(key);
    return; //return undefined just like localStorage
  }

  _clearCookies() {
    // empty all key/values in cookies
    document.cookie.split(';').forEach(c => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=Max-Age=-99999999');
    });

    return; //return undefined
  }
}
export default new Storage();
