import Storage from './../../storage';
export default class BasePlatform {
  constructor(p) {
    this.ttsEnabled = false;
  }
  /**
   * initialize the platform, should be called after dom is render
   */
  init(initCallback) {
    console.info('Initializing default platform');
    if (initCallback) initCallback();
  }

  /**
   * get the platform local storage, generally browser localStorage
   */
  localStorage() {
    if (typeof window !== 'undefined') {
      return window.localStorage;
    }
  }

  /**
   * exit the application
   */
  exit() {
    console.log('Exiting platform (default)');
    if (window && window.close) {
      console.info('Closing window');
      window.close();
    }
  }

  uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  getDeviceId() {
    let deviceId = Storage.getItem('ade:ihr:deviceId');
    if (!deviceId) {
      deviceId = this.uuid();
      Storage.setItem('ade:ihr:deviceId', deviceId);
    }
    return deviceId;
  }

  platformId() {
    return 'HTML5';
  }

  firmware() {
    return '1.0.0';
  }

  tts(output) {
    if (this.ttsEnabled) {
      this.utterance = new SpeechSynthesisUtterance();
      this.utterance.text = output;
      this.utterance.lang = 'en-US';
      window.speechSynthesis.speak(this.utterance || '');
    }
  }

  getHostURL() {
    return 'viziosecure.appliance.us';
  }

  getLocalConfigURL() {
    return 'https://global.api.iheart.com/api/v3/locationConfig';
  }

  getHostURLForConfig() {
    return 'viziosecure.appliance';
  }

  getSalt() {
    return '64225';
  }

  getTerminalId() {
    return '362';
  }

  getClientType() {
    return 'viziosecure';
  }

  getDeviceName() {
    return 'viziosecure';
  }

  getPlatformName() {
    return 'Vizio';
  }

  getAdobeAnaliticsName() {
    return 'vizio.appliance.us';
  }

  getTritonDevVariable() {
    return 'vizio';
  }
}
