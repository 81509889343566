import {
  playRadio,
  playNextTrack,
  playPrevTrack,
  done
} from '../reducers/station.js';

import { PLAYBACK_URL } from '../constants';

export const play = () => {
  const type = 'PLAY';
  return { type, DO_PLAY: true, DO_PAUSE: false };
};

export const pause = () => {
  const type = 'PAUSE';
  return { type, DO_PLAY: false, DO_PAUSE: true };
};

export const setDuration = currentTrackDuration => {
  const type = 'SET_DURATION';
  return { type, currentTrackDuration };
};

export const setCurrentTime = currentTrackTime => {
  const type = 'SET_CURRENT_TIME';
  return { type, currentTrackTime };
};

export const scrubBack = () => {
  const type = 'SCRUB_BACK';
  return { type };
};

export const scrubForward = () => {
  return async function(dispatch, getState) {
    const { audioPlayer } = getState();
    let isExceededTrackDuration =
      audioPlayer.currentTrackTime + 30 >= audioPlayer.currentTrackDuration;
    const type = 'SCRUB_FORWARD';
    dispatch({ type });
    if (isExceededTrackDuration) {
      dispatch(done());
    }
  };
};

export const updateAudio = data => {
  const type = 'UPDATE_AUDIO';
  return { type, payload: data };
};

export const resetPlayer = () => {
  const type = 'RESET_PLAYER';
  return { type };
};

export const playPause = () => {
  return async function(dispatch, getState) {
    const { audioPlayer } = getState();
    if (audioPlayer.DO_PLAY) {
      dispatch(trickPause());
    } else {
      dispatch(trickPlay());
    }
  };
};

export const rewindSkipBack = () => {
  return async function(dispatch, getState) {
    const { station } = getState();
    switch (station.contentType) {
      case 'PODCAST_EPISODE':
        dispatch(trickRW());
        break;
      default:
        dispatch(playPrevTrack());
    }
  };
};

export const fastForwardSkipForward = isMiniPlayer => {
  return async function(dispatch, getState) {
    const { station } = getState();
    switch (station.contentType) {
      case 'PODCAST_EPISODE':
        dispatch(trickFF());
        break;
      default:
        dispatch(playNextTrack(isMiniPlayer));
    }
  };
};

export const trickPlay = () => {
  return async function(dispatch, getState) {
    const { audioPlayer, station } = getState();
    if (station.contentType === 'LIVE' || station.contentType === 'RADIO') {
      dispatch(togglePlayStop());
    } else if (audioPlayer.DO_PAUSE) {
      dispatch(play());
    }
  };
};

export const trickPause = () => {
  return async function(dispatch, getState) {
    const { audioPlayer, station } = getState();
    if (station.contentType === 'LIVE' || station.contentType === 'RADIO') {
      dispatch(togglePlayStop());
    } else if (audioPlayer.DO_PLAY) {
      dispatch(pause());
    }
  };
};

export const trickPlayPause = () => {
  return async function(dispatch, getState) {
    const { audioPlayer, station } = getState();
    if (station.contentType === 'LIVE' || station.contentType === 'RADIO') {
      dispatch(togglePlayStop());
    } else if (audioPlayer.DO_PLAY) {
      dispatch(pause());
    } else if (audioPlayer.DO_PAUSE) {
      dispatch(play());
    }
  };
};

export const trickFF = () => {
  return async function(dispatch, getState) {
    const { station } = getState();
    if (station.contentType === 'PODCAST_EPISODE') {
      dispatch(scrubForward());
    }
  };
};

export const trickRW = () => {
  return async function(dispatch, getState) {
    const { station } = getState();
    if (station.contentType === 'PODCAST_EPISODE') {
      dispatch(scrubBack());
    }
  };
};

export const togglePlayPause = () => {
  return async function(dispatch, getState) {
    const { audioPlayer } = getState();
    if (audioPlayer.DO_PLAY) {
      dispatch(pause());
    } else {
      dispatch(play());
    }
  };
};

export const togglePlayStop = () => {
  return async function(dispatch, getState) {
    const { audioPlayer, station } = getState();
    if (audioPlayer.DO_PLAY) {
      dispatch(pause());
    } else {
      // Restart currently paused radio station
      const payload = {
        ...station,
        stationId: station.contentId,
        streamUrl: `${PLAYBACK_URL}${audioPlayer.currentStreamUrl}`,
        force: true
      };
      dispatch(playRadio(payload));
    }
  };
};

const initialState = {
  currentStreamUrl: '',
  audioPlayedSeconds: 0,
  currentTrackTime: 0,
  currentTrackDuration: 0,
  currentTrackTimeChanged: false,
  force: false
};

const SET_PLAYER_SOURCE = 'SET_PLAYER_SOURCE';
export function setPlayerSource({
  currentStreamUrl,
  image,
  title,
  upperTitle,
  subtitle,
  date,
  currentTrackTime,
  currentTrackDuration,
  force = false
}) {
  const type = SET_PLAYER_SOURCE;
  const payload = {
    image,
    title,
    upperTitle: upperTitle || subtitle,
    subtitle,
    date,
    currentStreamUrl: `${PLAYBACK_URL}${currentStreamUrl}`,
    currentTrackTime: currentTrackTime || 0,
    DO_PLAY: true,
    DO_PAUSE: false,
    currentTrackTimeChanged: currentTrackTime,
    audioPlayedSeconds: 0,
    force
  };
  return { type, payload };
}

const SET_PLAYER_POSITION = 'SET_PLAYER_POSITION';
export function setPlayerPosition(currentTrackTime) {
  const type = SET_PLAYER_POSITION;
  const payload = {
    currentTrackTime,
    currentTrackTimeChanged: true
  };
  return { type, payload };
}

const SET_PLAYER_STATION = 'SET_PLAYER_STATION';
export function setPlayerStation(contentId, contentType) {
  const type = SET_PLAYER_STATION;
  const payload = { contentId, contentType };
  return { type, payload };
}

export default function(state = initialState, action) {
  const { type, DO_PLAY, DO_PAUSE } = action;
  switch (type) {
    case 'SET_PLAYER_SOURCE':
      return { ...state, ...action.payload };
    case 'SET_PLAYER_POSITION':
      return { ...state, ...action.payload };
    case 'SET_PLAYER_STATION':
      return { ...state, ...action.payload };
    case 'PLAY':
      if (
        state.contentType === 'PODCAST_EPISODE' &&
        state.currentTrackTime === state.currentTrackDuration
      ) {
        return {
          ...state,
          ...{ DO_PLAY, DO_PAUSE, force: false },
          currentTrackTime: 0,
          currentTrackTimeChanged: true
        };
      } else {
        return {
          ...state,
          ...{ DO_PLAY, DO_PAUSE, force: false },
          currentTrackTimeChanged: false
        };
      }
    case 'PAUSE':
      return {
        ...state,
        ...{ DO_PLAY, DO_PAUSE, force: false },
        currentTrackTimeChanged: false
      };
    case 'SET_DURATION':
      let { currentTrackDuration } = action;
      return {
        ...state,
        currentTrackDuration,
        currentTrackTimeChanged: false
      };
    case 'SET_CURRENT_TIME':
      let { currentTrackTime } = action;

      if (currentTrackTime - 1 >= state.currentTrackTime) {
        return {
          ...state,
          currentTrackTime,
          audioPlayedSeconds: state.audioPlayedSeconds + 1,
          currentTrackTimeChanged: false
        };
      } else {
        return state;
      }

    case 'SET_FAVORITE':
      let { favorite } = action;
      return { ...state, ...{ favorite } };
    case 'SCRUB_BACK':
      let newBackCurrentTrackTime =
        state.currentTrackTime - 15 >= 0 ? state.currentTrackTime - 15 : 0;
      return {
        ...state,
        currentTrackTime: newBackCurrentTrackTime,
        currentTrackTimeChanged: true
      };
    case 'SCRUB_FORWARD':
      let isExceededTrackDuration =
        state.currentTrackTime + 30 >= state.currentTrackDuration;
      let newScrubState;

      if (isExceededTrackDuration) {
        newScrubState = {
          DO_PLAY: false,
          DO_PAUSE: true,
          currentTrackTime: state.currentTrackDuration
        };
      } else {
        newScrubState = {
          currentTrackTimeChanged: true,
          currentTrackTime: state.currentTrackTime + 30,
          DO_PLAY: true
        };
      }
      return {
        ...state,
        ...newScrubState
      };
    case 'RESET_PLAYER':
      return { ...initialState, DO_PLAY: false, DO_PAUSE: true };
    case 'UPDATE_AUDIO':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
