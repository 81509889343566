const BASE_URL = 'https://us.api.iheart.com';
const PLAYBACK_URL = 'https://i.iheart.com/proxy/';
const STREAM_PREFERENCE_ORDERING = [
  'secure_shoutcast_stream',
  'secure_pls_stream',
  'secure_hls_stream',
  'shoutcast_stream',
  'pls_stream',
  'hls_stream'
];
const LEADS = 'https://leads.radioedit.iheart.com';
const WEB_API = 'https://webapi.radioedit.iheart.com';
const LOGIN_OR_CREATE_OAUTH_USER = '/api/v1/account/loginOrCreateOauthUser';
const LOGIN_3RD_PARTY = '/api/v1/account/login3rdParty';
const LIVE_STATIONS = '/api/v2/content/liveStations';
const TOP_ARTISTS = '/api/v2/recs/genre';
const GRAPH_QL = '/graphql';
const CARDS = '/api/cards';
const GET_CODE = '/api/v1/account/getCode';
const GET_STATUS = '/api/v1/account/getStatus';
const UPGRADE_ANONYMOUS = '/api/v1/account/upgradeAnonAccount';
const ARTIST_PROFILE = '/api/v3/artists/profiles/{artistId}';
const ARTIST_BIO = '/api/v3/artists/profiles/{artistId}/bio';
const ARTIST_TOP_TRACKS = '/api/v3/catalog/tracks/artist/{artistId}';
const ARTIST_ALBUMS = '/api/v3/catalog/artist/{artistId}/albums';
const REMOVE_OAUTH_CRED = '/api/v1/account/removeOauthCred';
const RECENTLY_PLAYED_TRACKS = '/api/v1/history/{profileId}/getTrackEvents';
const RECENTLY_PLAYED_STATIONS = '/api/v1/history/{profileId}/getAll';
const RECENTLY_PLAYED_PLAYLIST = '/api/v2/playlists/{profileId}';
const SEARCH = '/api/v3/search/all';
const BASENAME = process.env.PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL;

const routes = {
  LOGIN_OR_CREATE_OAUTH_USER,
  LIVE_STATIONS,
  TOP_ARTISTS,
  GRAPH_QL,
  CARDS,
  GET_CODE,
  GET_STATUS,
  UPGRADE_ANONYMOUS,
  ARTIST_PROFILE,
  ARTIST_BIO,
  ARTIST_TOP_TRACKS,
  ARTIST_ALBUMS,
  REMOVE_OAUTH_CRED,
  RECENTLY_PLAYED_TRACKS,
  RECENTLY_PLAYED_STATIONS,
  RECENTLY_PLAYED_PLAYLIST,
  SEARCH,
  LOGIN_3RD_PARTY
};

export {
  BASENAME,
  BASE_URL,
  PLAYBACK_URL,
  STREAM_PREFERENCE_ORDERING,
  LEADS,
  WEB_API,
  routes
};
