import { getV2APIRoute, getV3APIRoute } from './utils';
import { WEB_API, routes } from '../constants';

export const getLiveStationContent = async stationId => {
  const url = '/api/v2/content/liveStations/' + stationId;
  const data = await getV2APIRoute(url);
  return data;
};

export const getStationCurrentTrack = async stationId => {
  let url = `/api/v3/live-meta/stream/${stationId}/currentTrackMeta`;
  const data = await getV3APIRoute(url);
  return data;
};

export const getPrivacyPolicyOrTermsOfUse = async fullQuery => {
  const url = `${WEB_API}${routes['GRAPH_QL']}${fullQuery}`;
  try {
    const data = await getV2APIRoute(url);
    const htmlData = data.data.content.item.payload.blocks.reduce(
      (prevHTML, curHTML) => `${prevHTML}${curHTML.html}`,
      ''
    );
    return htmlData;
  } catch (err) {
    console.error('Error getting data', err);
    throw err;
  }
};
