import React, { Component } from 'react';
import { connect } from 'react-redux';
import Hero from '../../components/Hero/Hero';
import GridMenusWrapper from '../../components/GridMenusWrapper';
import Storage from '../../lib/storage';
import {
  setPageTitle,
  setPageData,
  showLoadingSpinner,
  hideLoadingSpinner
} from '../../reducers/view';
import { updateFocus } from '../../reducers/appNavigation';
import { handleCardSelection, getItemModel } from '../../lib/utils';
import { updateCardPiles } from '../../reducers/cards';
import { playRadio, playFavorites } from '../../reducers/station';
import { addToast } from '../../reducers/toasts';
import {
  getPodcastProfile,
  getPodcastDirectory,
  getPodcastEpisode
} from '../../api/podcast';
import { forYou } from '../../api/recommendations';
import { getActivities } from '../../api/activities';
import { trending } from '../../api/trending';
import { getRecentlyPlayed } from '../../api/history';
import { s } from '../../lib/screen-size.js';
import { togglePlayStop } from '../../reducers/audioPlayer';
import { updateLocationState } from '../../lib/reactv-redux/ReacTVReduxReducer';

import './Home.scss';

class Home extends Component {
  constructor(props) {
    super(props);

    this.idForGrid = 'home'; // should match with path page
    this.OFFSET_SCROLL = s(1300);
    this.focusOnHero = this.focusOnHero.bind(this);
    this.fetchAndSetData = this.fetchAndSetData.bind(this);
    this.focusOnGridMenus = this.focusOnGridMenus.bind(this);
    this.handleCardOnEnter = this.handleCardOnEnter.bind(this);
  }

  getRandomHero(recommendations = []) {
    const lastHeroId = Storage.getItem('ade:ihr:last-hero-id');
    const possibleHeros =
      recommendations.filter(
        rec => rec.subType === 'ARTIST' || rec.subType === 'LIVE'
      ) || [];

    const getRandomInt = max => {
      return Math.floor(Math.random() * Math.floor(max));
    };

    let indForHero = getRandomInt(possibleHeros.length - 1);
    let newHero = possibleHeros[indForHero];

    if (!newHero || !newHero.content) {
      console.warn('No hero data');
      return;
    }

    if (newHero.content.id === Number(lastHeroId)) {
      indForHero = getRandomInt(possibleHeros.length - 1);
      newHero = possibleHeros[indForHero];
    }
    Storage.setItem('ade:ihr:last-hero-id', newHero.content.id);

    if (newHero && newHero.subType !== 'ARTIST' && possibleHeros.length > 0) {
      let possibleArtists = possibleHeros.filter(
        rec => rec.subType === 'ARTIST'
      );

      let indForRandomImage = getRandomInt(possibleArtists.length - 1);

      newHero.randomArtistImage =
        possibleArtists[indForRandomImage] &&
        possibleArtists[indForRandomImage].imagePath;
    }

    if (newHero) {
      newHero.content._model = getItemModel({ item: newHero.content });
    }

    return newHero;
  }

  componentDidMount() {
    this.fetchAndSetData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      ((prevProps.session.userProfile &&
        prevProps.session.userProfile.accountType === 'ANONYMOUS') ||
        !prevProps.session.userProfile) &&
      this.props.session.userProfile &&
      this.props.session.userProfile.accountType !== 'ANONYMOUS'
    ) {
      this.fetchAndSetData(true);
    }
  }

  async fetchAndSetData(refetchHero) {
    this.props.showLoadingSpinner();
    const { hero, session, addToast } = this.props;

    const { values: recommendations } = await forYou();

    const cardPiles = [{ key: 'popular', data: recommendations }];

    if (!hero || refetchHero) {
      cardPiles.push({
        key: 'hero',
        data: this.getRandomHero(recommendations)
      });
    }

    if (this.props.menu && this.props.menu.appNavigation) {
      document
        .querySelector('.mainContainer')
        .scrollTo(this.props.menu.appNavigation.lastScrollValue || 0, 0);
    }

    if (!this.props.trends) {
      const trends = await trending();
      cardPiles.push({ key: 'trends', data: trends });
    }

    if (!this.props.activities) {
      const activities = await getActivities();
      cardPiles.push({ key: 'activities', data: activities });
    }

    let recentlyPlayed = [];
    const recentlyResponse = await getRecentlyPlayed();
    const podcastDirectoryResponse = await getPodcastDirectory();
    let recentlyPlayedEpisode =
      podcastDirectoryResponse && podcastDirectoryResponse.recentlyPlayed;

    if (recentlyPlayedEpisode) {
      let recentEpisodeData = await getPodcastEpisode(recentlyPlayedEpisode.id);
      recentEpisodeData = recentEpisodeData && recentEpisodeData.episode;
      recentEpisodeData.podcast = await getPodcastProfile(
        recentEpisodeData.podcastId
      );

      if (recentEpisodeData && recentEpisodeData.podcast) {
        recentlyPlayed.push({
          ...recentEpisodeData.podcast,
          stationType: 'PODCAST',
          secondsPlayed: recentlyPlayedEpisode.secondsPlayed,
          duration: recentEpisodeData.duration,
          episodeId: recentlyPlayedEpisode.id,
          title: `${recentEpisodeData.podcast.title} - ${recentEpisodeData.title}`
        });
      }
    }

    if (
      recentlyResponse &&
      recentlyResponse.data &&
      recentlyResponse.data.hits
    ) {
      let recentlyPlayedPlaylist = recentlyResponse.data.hits;
      if (recentlyPlayedPlaylist.length > 0) {
        recentlyPlayedPlaylist = recentlyPlayedPlaylist
          .filter(
            item =>
              item.stationType !== 'FAVORITES' && item.stationType !== 'PODCAST'
          )
          .map(item => {
            if (item.stationType === 'LIVE') {
              return item.content[0];
            }

            return item;
          })
          .filter(item => item);
      }
      recentlyPlayed = recentlyPlayed.concat(recentlyPlayedPlaylist);
    }
    cardPiles.push({ key: 'recentlyPlayed', data: recentlyPlayed });

    this.props.updateCardPiles(cardPiles);
    if (
      this.props.menu &&
      this.props.menu.appNavigation &&
      this.props.menu.appNavigation.currentFocus !== 'container/miniPlayer'
    ) {
      this.props.setPageTitle();
    }
    this.props.hideLoadingSpinner();

    if (!this.props.menu || !this.props.menu.appNavigation) {
      this.props.updateFocus('container/mainMenu');
    }

    if (
      session.userProfile &&
      session.userProfile.accountType === 'ANONYMOUS'
    ) {
      addToast({ type: 'home-anon' });
    }
  }

  focusOnGridMenus() {
    const { recentlyPlayed } = this.props;
    if (recentlyPlayed && recentlyPlayed.length) {
      this.props.updateFocus('home/gridMenus/home-main-recentlyPlayed');
    } else {
      this.props.updateFocus('home/gridMenus/home-main-recommendations');
    }
  }

  focusOnHero() {
    const { updateFocus } = this.props;
    let mainContainer = document.querySelector('.mainContainer');
    if (mainContainer.scrollLeft) {
      // reset scroll, if needed, when navigating back to Hero
      mainContainer.scrollTo(0, 0);
    }
    updateFocus('home/content');
  }

  isHeroFocused() {
    return this.props.currentFocus === 'home/content';
  }

  async handleCardOnEnter(card) {
    handleCardSelection.call(this, card);
  }

  renderHero() {
    const {
      hero,
      onUp,
      onDown,
      station,
      audioPlayer,
      togglePlayStop
    } = this.props;
    if (hero && hero.contentId) {
      return (
        <Hero
          isPlaying={
            audioPlayer.DO_PLAY &&
            !audioPlayer.DO_PAUSE &&
            station.contentId === hero.contentId
          }
          hero={hero.content}
          isFirstTimeVisiting={false}
          focused={this.isHeroFocused()}
          onUp={onUp}
          onDown={onDown}
          onRight={this.focusOnGridMenus}
          onEnter={() => {
            if (station.contentId === hero.contentId) {
              togglePlayStop();
            } else {
              handleCardSelection.call(this, hero.content);
            }
          }}
        />
      );
    }
  }

  renderGridMenus() {
    const {
      recommendations,
      trends,
      activities,
      favorites,
      onUp,
      onDown,
      recentlyPlayed
    } = this.props;

    let menusData = [];

    if (recentlyPlayed && recentlyPlayed.length) {
      let newRecentlyPlayed = recentlyPlayed.slice(0, 4);

      menusData.push({
        label: 'Recently Played',
        menuid: 'home-main-recentlyPlayed',
        data: newRecentlyPlayed,
        constantWidth: true,
        allWider: true,
        recentlyPlayed: true,
        xCards: 10,
        onUp,
        onDown,
        CUSTOM_AMOUNT_TO_MOVE_SCROLL_BY: 450
      });
    }

    let newRecs = recommendations;
    if (recommendations && recommendations.length) {
      if (!favorites || !Object.keys(favorites).length) {
        const firstCard = {
          label: 'Select Genres',
          subLabel:
            "Tell us all the genres you like. We'll suggest stations just For You.",
          imagePath: null,
          kind: 'selectGenre'
        };
        if (newRecs && newRecs[0].label !== firstCard.label)
          newRecs.unshift(firstCard);
      }

      menusData.push({
        label: 'For you',
        menuid: 'home-main-recommendations',
        data: newRecs,
        xCards: 10,
        onUp,
        onDown
      });
    }

    if (trends && trends.length && recommendations) {
      menusData.push({
        label: 'Trending',
        menuid: 'home-main-trending',
        data: trends,
        xCards: 10,
        onUp,
        onDown
      });
    }

    if (activities && activities.length) {
      menusData.push({
        label: 'Activities',
        menuid: 'home-main-activities',
        data: activities,
        xCards: 10,
        onUp,
        onDown
      });
    }

    return (
      <GridMenusWrapper
        onLeft={this.focusOnHero}
        className="home__gridMenus"
        idForGrid={this.idForGrid}
        menusData={menusData}
        onEnter={this.handleCardOnEnter}
        AMOUNT_TO_MOVE_SCROLL_BY={250}
        OFFSET_SCROLL={this.OFFSET_SCROLL}
      />
    );
  }

  renderBackground() {
    const { hero } = this.props;
    const showBackground = hero && hero.subType === 'ARTIST';

    return (
      <div
        className="home__background"
        style={{ backgroundImage: showBackground && `url(${hero.imagePath})` }}
      >
        <div className="home__gradient home__gradient--left" />
        <div className="home__gradient home__gradient--right" />
      </div>
    );
  }

  render() {
    return (
      <div className="home">
        {this.renderBackground()}
        {this.renderHero()}
        {this.renderGridMenus()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    session,
    station,
    audioPlayer,
    entitlements,
    cards,
    taste,
    appNavigation,
    locationStateHistory
  } = state;

  return {
    session,
    entitlements,
    station,
    audioPlayer,
    recommendations: cards.popular,
    trends: cards.trends,
    hero: cards.hero,
    activities: cards.activities,
    recentlyPlayed: cards.recentlyPlayed,
    favorites: taste.favorites,
    menu: locationStateHistory[appNavigation.page],
    appNavigation: appNavigation,
    currentFocus: appNavigation.currentFocus
  };
};

export default connect(mapStateToProps, {
  updateLocationState,
  togglePlayStop,
  addToast,
  playRadio,
  playFavorites,
  updateFocus,
  setPageTitle,
  updateCardPiles,
  setPageData,
  showLoadingSpinner,
  hideLoadingSpinner
})(Home);
