import React from 'react';

const Icon = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4569 16.3426C13.322 17.0028 12.0028 17.381 10.5953 17.381C6.34793 17.381 2.90479 13.9378 2.90479 9.69048C2.90479 5.44314 6.34793 2 10.5953 2C14.8426 2 18.2857 5.44314 18.2857 9.69048C18.2857 11.6285 17.5689 13.3991 16.3858 14.7515L21.0444 20.1865C21.4937 20.7107 21.433 21.4998 20.9088 21.9491C20.3847 22.3984 19.5955 22.3377 19.1462 21.8135L14.4569 16.3426ZM15.2857 9.69048C15.2857 12.281 13.1857 14.381 10.5953 14.381C8.00478 14.381 5.90479 12.281 5.90479 9.69048C5.90479 7.1 8.00478 5 10.5953 5C13.1857 5 15.2857 7.1 15.2857 9.69048Z"
      fill={fill ? fill : '#FFFFFF'}
    />
  </svg>
);

export default Icon;
