import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import splashGif from '../../img/splash_logo_animation.gif';
import { loadAnonymousSession } from '../../reducers/session';
import {
  getGlobalConfiguration,
  getLocalConfiguration
} from '../../api/configuration';
import { getPlaylistInfo } from '../../api/playlists';
import {
  getLiveStationContent,
  getStationCurrentTrack
} from '../../api/content';
import { getUserGenres } from '../../api/taste';
import {
  getPodcastProfile,
  getPodcastEpisodes,
  getPodcastEpisode
} from '../../api/podcast';
import { handleCardSelection, getItemModel } from '../../lib/utils';
import Storage from '../../lib/storage';

import MiniPlayer from '../MiniPlayer/MiniPlayer';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import MainMenu from '../MainMenu/MainMenu';
import ExitModal from '../ExitModal';
import Toasts from '../Toasts';
import Screensaver from '../Screensaver';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Home from '../../Pages/Home';
import GenreListing from '../../Pages/GenreListing';
import ViewAllPage from '../../Pages/ViewAllPage';
import YourLibrary from '../../Pages/YourLibrary';
import Artist from '../../Pages/Artist';
import Podcast from '../../Pages/Podcast';
import Playlist from '../../Pages/Playlist';
import ProfileDetails from '../../Pages/ProfileDetails';
import Search from '../../Pages/Search';
import PlayerPage from '../../Pages/Player';
import Settings from '../../Pages/Settings';
import ExclamationIcon from '../../img/exclamationIcon';
import {
  getAuthCode,
  loginOrCreateOauthUser,
  login3rdParty
} from '../../api/account';
import { getUserProfile } from '../../api/profile';
import {
  setAppNavigation,
  setCurrentPage,
  updateFocus,
  updateAddPopup
} from '../../reducers/appNavigation';
import {
  updateMenu,
  clearMenu,
  clearAllPageMenus,
  clearMenusFocus
} from '../../lib/reactv-redux/MenusReducer';
import {
  clearLocationState,
  clearAllLocationStates,
  updateLocationState
} from '../../lib/reactv-redux/ReacTVReduxReducer';
import ReactvPlatforms from '../../lib/reactv-platforms/index';
import { KeyEvents, KeyMaps } from '../../lib/reactv-redux/index';
import {
  playPause,
  rewindSkipBack,
  fastForwardSkipForward,
  trickPlay,
  trickPause,
  trickFF,
  trickRW
} from '../../reducers/audioPlayer';
import { setUserGenres } from '../../reducers/taste';
import { addToast } from '../../reducers/toasts';
import {
  playArtist,
  playPodcastEpisode,
  playPlaylist,
  playRadio,
  playNextTrack,
  playPrevTrack,
  done
} from '../../reducers/station';
import {
  storeViewAll,
  clearViewAll,
  setLoadedPageToDefault
} from '../../reducers/view';
import { trackPageView } from '../../lib/adobe-analytics-api';

import './MainContainer.scss';

class MainContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      keys: new KeyEvents(),
      showScreensaver: false
    };

    this.mainContainerRef = React.createRef();

    this.changeSection = this.changeSection.bind(this);
    this.focusOnMainMenu = this.focusOnMainMenu.bind(this);
    this.focusOnMiniPlayer = this.focusOnMiniPlayer.bind(this);
    this.screenSaverOnKeydown = this.screenSaverOnKeydown.bind(this);
    this.playLastPlayedStation = this.playLastPlayedStation.bind(this);
    this.focusOnContentSection = this.focusOnContentSection.bind(this);
    this.retrieveConfigAndData = this.retrieveConfigAndData.bind(this);
  }

  async setupConfig() {
    try {
      const globalConfigData = await getGlobalConfiguration();
      if (!globalConfigData) {
        return false;
      }

      if (
        !globalConfigData.supported &&
        process.env.REACT_APP_CHECK_USER_IN_SUPPORTED_LOCATION !== 'false'
      ) {
        return 'LOCATION_ERROR';
      }
      const authCode = await getAuthCode();

      if (
        process.env.REACT_APP_CHECK_USER_IN_SUPPORTED_LOCATION === 'false' ||
        (authCode.errors &&
          authCode.errors.length &&
          authCode.errors[0].code === 125)
      ) {
        console.info('Device already registered, logging in');
        await login3rdParty();
      } else {
        await loginOrCreateOauthUser();
      }
      const userProfile = await getUserProfile();
      await getLocalConfiguration();
      const params = new URLSearchParams(this.props.location.search);

      if (
        params.get('action') !== 'play' && // purpose: avoid this action if coming from a deep-link
        userProfile &&
        userProfile.preferences &&
        userProfile.preferences['play.on.startup'] === '1'
      ) {
        window.setTimeout(this.playLastPlayedStation, 1000);
      }
      const { genreIds } = await getUserGenres(userProfile.profileId);
      if (genreIds) {
        let favorites = {};
        genreIds.map(id => {
          favorites[id] = true;
          return null;
        });
        this.props.setUserGenres(favorites);
      }
    } catch (err) {
      console.log(err);
      return false;
    }

    return true;
  }

  async retrieveConfigAndData() {
    console.log('Device initialization is complete');
    this.setState({ deviceIsReady: true });

    const configResult = await this.setupConfig();
    if (process.env.REACT_APP_CHECK_USER_IN_SUPPORTED_LOCATION !== 'false') {
      if (configResult !== true) {
        this.setState({
          loading: false,
          isGeneralError: configResult === false,
          isGeolocationError: configResult === 'LOCATION_ERROR'
        });
        return;
      }
    }
    this.checkForDeeplinks();

    this.setState({ loading: false });
  }

  async componentDidMount() {
    const { playNextTrack, playPrevTrack, updateAddPopup } = this.props;
    ReactvPlatforms.platform.init(this.retrieveConfigAndData);
    this.backBinding = this.state.keys.subscribeTo('Back', () => {
      if (process.env.REACT_APP_TOUCH === 'true') return;
      if (this.state.showScreensaver) return;
      if (this.props.appNavigation.addPopup.showAddPopup) {
        updateAddPopup(false, '', 1);
        return;
      }
      if (this.props.location.pathname === '/') {
        if (this.props.appNavigation.currentFocus === 'exitModal') {
          this.props.updateFocus(this.props.appNavigation.lastFocus);
          return;
        }
        this.props.updateFocus('exitModal');
      } else {
        if (this.props.location.pathname.includes('viewAll')) {
          this.props.clearMenusFocus('viewAllPage');
          this.props.clearLocationState(this.props.location.pathname);
          this.props.clearViewAll(
            this.props.location.pathname + this.props.location.search
          );
          this.props.setLoadedPageToDefault(
            this.props.location.search.slice(8)
          );
        } else {
          this.props.clearMenusFocus(this.currentPage);
          this.props.clearLocationState(this.currentPage);
        }
        this.preventUpdatingLocationState = true;

        this.props.history.goBack();
      }
    });
    this.playBinding = this.state.keys.subscribeTo('Play', () => {
      this.props.trickPlay();
    });
    this.pauseBinding = this.state.keys.subscribeTo('Pause', () => {
      this.props.trickPause();
    });
    this.stopBinding = this.state.keys.subscribeTo('Stop', () => {
      this.props.trickPause();
    });
    this.exitBinding = this.state.keys.subscribeTo('Exit', async () => {
      await this.props.done(true);
      window.VIZIO.exitApplication();
    });
    this.nextTrackBinding = this.state.keys.subscribeTo(
      'MediaTrackNext',
      () => {
        playNextTrack();
      }
    );
    this.prevTrackBinding = this.state.keys.subscribeTo(
      'MediaTrackPrevious',
      () => {
        playPrevTrack();
      }
    );
    this.ffBinding = this.state.keys.subscribeTo('FastForward', () => {
      this.props.trickFF();
    });
    this.rwBinding = this.state.keys.subscribeTo('Rewind', () => {
      this.props.trickRW();
    });
    this.playPauseBinding = this.state.keys.subscribeTo('PlayPause', () => {
      this.props.playPause();
    });
    this.fastForwardSkipForwardBinding = this.state.keys.subscribeTo(
      'FastForwardSkipForward',
      () => {
        this.props.fastForwardSkipForward(
          this.props.location.pathname !== '/player'
        );
      }
    );
    this.rewindSkipBackBinding = this.state.keys.subscribeTo(
      'RewindSkipBack',
      () => {
        this.props.rewindSkipBack();
      }
    );
    document.addEventListener('keydown', e => {
      const name = KeyMaps(e.keyCode);
      if (!name) {
        this.props.addToast({ type: 'unsupported-key' });
      }
    });
    this.currentPage = this.props.location.pathname;
    this.props.setCurrentPage(this.currentPage);
  }

  async checkForDeeplinks() {
    const params = new URLSearchParams(this.props.location.search);
    const isPartnerButtonLaunch =
      params.get('launch-source') === 'partner-button';
    trackPageView(isPartnerButtonLaunch ? 'partner-button' : 'app-menu');
    if (
      params.get('action') === 'play' &&
      params.get('type') &&
      params.get('stationId')
    ) {
      switch (params.get('type')) {
        case 'live':
          const live = await getLiveStationContent(params.get('stationId'));
          if (!live.hits || !live.hits[0]) return;
          let card = { ...live.hits[0] };
          card._model = getItemModel({
            item: card,
            dataContentType: params.get('type').toUpperCase()
          });
          handleCardSelection.call(this, card);
          break;
        case 'artist':
          this.props.playArtist(params.get('stationId'));
          break;
        case 'playlist':
          let ids = params.get('stationId').split('::');
          const stationProfile = {
            userId: ids[0],
            collectionId: ids[1]
          };
          const playlist = await getPlaylistInfo(stationProfile);
          this.props.playPlaylist(playlist, 0);
          break;
        case 'podcast':
          const podcast = await getPodcastProfile(params.get('stationId'));
          if (params.get('episodeId')) {
            let response = await getPodcastEpisode(params.get('episodeId'));
            let episode = response.episode;
            episode.podcastTitle = podcast.title;
            this.props.playPodcastEpisode(episode);
          } else {
            const response = await getPodcastEpisodes(params.get('stationId'));
            let episode = response.episodes[0];
            episode.podcastTitle = podcast.title;
            this.props.playPodcastEpisode(episode);
          }
          break;
        default:
      }

      this.props.updateFocus('container/miniPlayer');
      this.props.history.push('/player');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.menu &&
      this.props.menu.appNavigation &&
      prevProps.appNavigation.page !== this.props.appNavigation.page
    ) {
      let newAppNavigation = this.props.menu.appNavigation;
      if (this.props.appNavigation.currentFocus.startsWith('container')) {
        newAppNavigation.currentFocus = this.props.appNavigation.currentFocus;
      }

      this.props.setAppNavigation(newAppNavigation);
      if (
        this.props.menu &&
        this.props.menu.navigationMenu &&
        this.props.appNavigation.page.includes('viewAllPage')
      ) {
        this.props.updateMenu(
          'viewAllPage-mainMenu-0',
          this.props.menu.navigationMenu
        );
      }
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      const {
        location,
        clearMenu,
        updateLocationState,
        clearAllPageMenus,
        setCurrentPage,
        view
      } = this.props;
      let pageToUpdate =
        location.pathname === '/'
          ? 'home'
          : location.pathname.includes('artist') &&
            location.pathname.includes('albums')
          ? 'albums'
          : location.pathname.split('/')[1]; // getting first part of GridMenu mid prop

      if (location.pathname.includes('viewAllPage')) {
        pageToUpdate = 'viewAllPage';
      }
      this.currentPage = this.props.location.pathname;
      setCurrentPage(this.currentPage);

      if (location.pathname.includes('viewAllPage')) {
        if (view.viewAllData) {
          let mid = location.pathname + location.search;
          this.props.storeViewAll(mid, view.viewAllData);
        }
      }

      if (prevProps.location.pathname.includes('viewAllPage')) {
        this.props.clearViewAll();
      }

      if (this.preventUpdatingLocationState) {
        this.preventUpdatingLocationState = false;
      } else {
        if (prevProps.location.pathname.includes('viewAllPage')) {
          updateLocationState(
            prevProps.location.pathname,
            prevProps.appNavigation,
            prevProps.navigation.menus['viewAllPage-mainMenu-0']
          );
        }

        if (
          this.currentPage !== 'home' &&
          this.currentPage !== 'search' &&
          this.currentPage !== 'library'
        ) {
          clearMenu(pageToUpdate);
          if (pageToUpdate !== 'viewAll') {
            clearAllPageMenus(pageToUpdate);
          }
          if (pageToUpdate === 'albums') {
            clearMenu('albums-artist-tracks');
          }
        }
      }
    }

    if (
      this.props.audioPlayer.currentStreamUrl &&
      this.props.station.contentType &&
      !prevProps.audioPlayer.currentStreamUrl &&
      process.env.REACT_APP_TOUCH !== 'true'
    ) {
      this.screenSaverTimeout = window.setTimeout(() => {
        this.setState({ showScreensaver: true });
      }, 20000);
      document.addEventListener('keydown', this.screenSaverOnKeydown, {
        capture: true
      });
    }
  }

  screenSaverOnKeydown(e) {
    window.clearTimeout(this.screenSaverTimeout);
    if (this.state.showScreensaver) {
      e.stopImmediatePropagation();
      window.setTimeout(() => {
        this.setState({ showScreensaver: false });
      }, 20);
    }

    if (
      this.props.audioPlayer.currentStreamUrl &&
      this.props.station.contentType
    ) {
      this.screenSaverTimeout = window.setTimeout(() => {
        this.setState({ showScreensaver: true });
      }, 20000);
    }
  }

  async playLastPlayedStation() {
    const {
      playArtist,
      playPodcastEpisode,
      playPlaylist,
      playRadio
    } = this.props;
    let lastPlayedStation = Storage.getItem('ade:ihr:last-played-station');
    if (lastPlayedStation) {
      lastPlayedStation = JSON.parse(lastPlayedStation);
      switch (lastPlayedStation.type) {
        case 'ARTIST':
          playArtist(lastPlayedStation.data);
          break;
        case 'PODCAST_EPISODE':
          playPodcastEpisode(lastPlayedStation.data);
          break;
        case 'PLAYLIST':
          const stationProfile = {
            userId: lastPlayedStation.data.userId,
            collectionId: lastPlayedStation.data.id
          };
          const playlist = await getPlaylistInfo(stationProfile);
          playPlaylist(playlist, 0);
          break;
        case 'LIVE':
          if (lastPlayedStation.data && lastPlayedStation.data.stationId) {
            let liveRadioCurrentSong = await getStationCurrentTrack(
              lastPlayedStation.data.stationId
            );
            playRadio({ ...lastPlayedStation.data, liveRadioCurrentSong });
          }
          break;
        default:
          break;
      }
    }
  }

  changeSection(newSection) {
    this.props.clearAllLocationStates();
    this.props.clearMenusFocus(this.currentPage);
    this.props.history.push(newSection.path);
  }

  focusOnContentSection() {
    const {
      location,
      appNavigation: { lastFocus, currentFocus }
    } = this.props;

    if (lastFocus) {
      const lastFocusedPage = lastFocus.split('/')[0];
      const newFocusedPage =
        location.pathname === '/' ? 'home' : location.pathname.split('/')[1];

      if (
        lastFocusedPage === newFocusedPage ||
        (newFocusedPage === 'artist' && lastFocusedPage === 'albums')
      ) {
        this.props.updateFocus(lastFocus);
        return;
      }
    }

    if (location.pathname === '/') {
      this.props.updateFocus('home/content');
    }

    if (location.pathname === '/library') {
      const {
        library: { libraryIsEmpty, isMenuVisible, firstMenu }
      } = this.props;
      if (!libraryIsEmpty) {
        if (isMenuVisible) this.props.updateFocus('library/content');
        else this.props.updateFocus(firstMenu);
      } else if (this.props.audioPlayer.currentStreamUrl) {
        let focusOn = 'container/miniPlayer';
        if (currentFocus === focusOn) focusOn = 'container/mainMenu';
        this.props.updateFocus(focusOn);
      }
    }

    if (location.pathname === '/search') {
      this.props.updateFocus('search/content');
    }

    if (location.pathname.includes('/settings')) {
      this.props.updateFocus('settings/content');
    }

    if (location.pathname.includes('/viewAllPage')) {
      this.props.updateFocus('viewAllPage/gridMenus/viewAllPage-mainMenu-0');
    }

    if (location.pathname.includes('/profileDetails')) {
      this.props.updateFocus('profileDetails/content');
    }

    if (location.pathname.includes('/podcast')) {
      this.props.updateFocus('podcast/content');
    }

    if (location.pathname.includes('/artist')) {
      if (location.pathname.includes('albums')) {
        this.props.updateFocus('albums/content');
      } else {
        this.props.updateFocus('artist/content');
      }
    }
  }

  focusOnMainMenu() {
    this.props.updateFocus('container/mainMenu');
  }

  focusOnMiniPlayer() {
    if (this.props.audioPlayer.currentStreamUrl) {
      this.props.updateFocus('container/miniPlayer');
    }
  }

  isMainMenuFocused() {
    return this.props.appNavigation.currentFocus === 'container/mainMenu';
  }

  isMiniPlayerFocused() {
    return this.props.appNavigation.currentFocus === 'container/miniPlayer';
  }

  componentWillUnmount() {
    if (this.backBinding) this.backBinding.unsubscribe();
    if (this.playBinding) this.playBinding.unsubscribe();
    if (this.pauseBinding) this.pauseBinding.unsubscribe();
    if (this.stopBinding) this.stopBinding.unsubscribe();
    if (this.exitBinding) this.exitBinding.unsubscribe();
    if (this.nextTrackBinding) this.nextTrackBinding.unsubscribe();
    if (this.prevTrackBinding) this.prevTrackBinding.unsubscribe();
    if (this.ffBinding) this.ffBinding.unsubscribe();
    if (this.rwBinding) this.rwBinding.unsubscribe();
    if (this.playPauseBinding) this.playPauseBinding.unsubscribe();
    if (this.fastForwardSkipForwardBinding)
      this.fastForwardSkipForwardBinding.unsubscribe();
    if (this.rewindSkipBackBinding) this.rewindSkipBackBinding.unsubscribe();
  }

  render() {
    const {
      loading,
      deviceIsReady,
      isGeneralError,
      isGeolocationError,
      showScreensaver
    } = this.state;
    const {
      location: { pathname },
      appNavigation
    } = this.props;

    const isArtist = pathname.startsWith('/artist');
    const isPodcast = pathname.startsWith('/podcast');
    const isPlaylist = pathname.startsWith('/playlist');
    const isProfileDetails = pathname.startsWith('/profileDetails');
    const isGenreListing = pathname.startsWith('/genreListing');
    const isViewAllPage = pathname.startsWith('/viewAllPage');
    const isPlayer = pathname.startsWith('/player');

    const enhanceWithNavigationProps = (Component, props) => (
      <Component
        {...props}
        onUp={
          isArtist || isPodcast || isPlaylist || isGenreListing || isViewAllPage
            ? null
            : this.focusOnMainMenu
        }
        onDown={this.focusOnMiniPlayer}
      />
    );
    if (isGeneralError || isGeolocationError) {
      return (
        <div className="mainContainer mainContainer--geolocationError">
          <div className="mainContainer__mark">
            <ExclamationIcon />
          </div>
          <h1>
            Sorry, but iHeartRadio is not currently{' '}
            {isGeneralError ? 'available!' : 'supported in your location!'}
          </h1>
        </div>
      );
    }

    return (
      <div className="mainContainer" ref={this.mainContainerRef}>
        {loading || !deviceIsReady ? (
          <header className="mainContainer__loading">
            <img src={splashGif} alt="logo" />
          </header>
        ) : (
          <React.Fragment>
            {appNavigation && appNavigation.currentFocus === 'exitModal' && (
              <ExitModal />
            )}
            {showScreensaver && (
              <Screensaver
                dismiss={() => this.setState({ showScreensaver: false })}
              />
            )}
            <Toasts />
            <MainMenu
              focused={this.isMainMenuFocused()}
              onEnter={this.changeSection}
              onDown={this.focusOnContentSection}
              hideMenu={isGenreListing}
              shouldShowTitle={
                isArtist ||
                isPodcast ||
                isPlaylist ||
                isViewAllPage ||
                isProfileDetails ||
                isPlayer
              }
              withoutGradient={isArtist || isPodcast || isPlaylist}
            />
            <LoadingSpinner />
            <AudioPlayer />
            <div
              className="mainContainer__content"
              style={{
                transform: `translateX(${appNavigation.translateXMainContent}px)`
              }}
            >
              <Route
                exact
                path="/"
                render={props => enhanceWithNavigationProps(Home, props)}
              />
              <Route
                exact
                path="/library"
                render={props => enhanceWithNavigationProps(YourLibrary, props)}
              />
              <Route
                exact
                path="/search"
                render={props => enhanceWithNavigationProps(Search, props)}
              />
              <Route
                path="/settings"
                render={props => enhanceWithNavigationProps(Settings, props)}
              />
              <Route
                exact
                path="/genreListing"
                render={props =>
                  enhanceWithNavigationProps(GenreListing, props)
                }
              />
              <Route
                exact
                path="/viewAllPage/:title?/:redirectedFrom?/:contentId?"
                render={props => enhanceWithNavigationProps(ViewAllPage, props)}
              />
              <Route
                path="/artist/:artistId/:contentType?/:contentId?"
                render={props => enhanceWithNavigationProps(Artist, props)}
              />
              <Route
                path="/podcast/:podcastId/:contentType?/:contentId?"
                render={props => enhanceWithNavigationProps(Podcast, props)}
              />
              <Route
                path="/playlist/:playlistId/:campaign?/:type?/:category?"
                render={props => enhanceWithNavigationProps(Playlist, props)}
              />
              <Route
                exact
                path="/profileDetails/:contentType/:contentId"
                render={props =>
                  enhanceWithNavigationProps(ProfileDetails, props)
                }
              />
              <Route
                exact
                path="/player"
                render={props => enhanceWithNavigationProps(PlayerPage, props)}
              />
            </div>
            <MiniPlayer
              focused={this.isMiniPlayerFocused()}
              onUp={this.focusOnContentSection}
              hide={isPlayer || isGenreListing}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    locationStateHistory,
    session,
    station,
    entitlements,
    appNavigation,
    audioPlayer,
    library,
    navigation,
    view
  } = state;

  return {
    menu: locationStateHistory[appNavigation.page],
    audioPlayer,
    session,
    station,
    entitlements,
    appNavigation,
    library,
    navigation,
    view
  };
};

export default connect(mapStateToProps, {
  done,
  addToast,
  rewindSkipBack,
  fastForwardSkipForward,
  trickPlay,
  trickPause,
  trickFF,
  trickRW,
  loadAnonymousSession,
  clearLocationState,
  clearMenu,
  clearMenusFocus,
  clearAllLocationStates,
  clearAllPageMenus,
  clearViewAll,
  setAppNavigation,
  setCurrentPage,
  setUserGenres,
  storeViewAll,
  setLoadedPageToDefault,
  updateMenu,
  updateFocus,
  updateAddPopup,
  updateLocationState,
  playPause,
  playArtist,
  playPodcastEpisode,
  playPlaylist,
  playRadio,
  playNextTrack,
  playPrevTrack
})(MainContainer);
